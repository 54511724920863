.login-page {
    background-image: url('../img/contract.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #fff;
}
.pricing-page {
    background-image: url('../img/contract.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #fff;
}
.pricing-page::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.25);
}
.login-box  {
     background-color:rgba(255,255,255,0.6);
}
.login-box div {
    background-color:transparent;
}

